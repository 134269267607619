<template>
  <div class='template' :style="{backgroundImage:`url(${page?.background})`}">    
      <div class='top'>        
        <ImageTitle transparent :title="page.title"/>          
        <article class='text-content'>              
            <div class="events main">
                <div class="event" v-for="(event, id) in mainEvents" :key="id" :class="[event.type]">
                    <p class="event-title" v-html="event.label"/>
                    <p class="event-date" v-html="event.value"/>
                </div>            
            </div>
            <div class="events">
                <div class="event" v-for="(event, id) in otherEvents" :key="id" :class="[event.type]">
                    <p class="event-title" v-html="event.label"/>
                    <p class="event-date" v-html="event.value"/>
                </div>            
            </div>        
            <div class="linear-holder">
                <LinearButton :to="page?.previous" previous/>
                <LinearButton :to="page?.next"/>
            </div>                    
      </article>            
      </div>      
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Events',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0                 
        }
    },
    computed: {        
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        events() {
            //let eventsTest = "<p>&nbsp;</p>\n<p>[event type=\"big\" value=\"July 9-10\" label=\"Quaker State 400 Presented by Walmart NASCAR Weekend\"]&nbsp;</p>\n<p>[event type=\"big\" value=\"November-December\" label=\"Drive Thru Holiday Lights\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"March 26\" label=\"Import Alliance\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"April 2\" label=\"American Flat Track Motorcycle Race\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"April 9\" label=\"Monster Jam, Show 1\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"April 10\" label=\"Monster Jam, Show 2\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"April 16\" label=\"AMA Supercross FIM World Championship\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"May 7\" label=\"Caffeine &amp; Octane\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"May\" label=\"10+ High School Graduations\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"May\" label=\"11 Days: Spring Atlanta Fair\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"June\" label=\"Thursday Thunder Racing Series\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"September 10\" label=\"Caffeine &amp; Octane\"]&nbsp;</p>\n<p>[event type=\"small\" value=\"October\" label=\"10 Days: Georgia State Fair\"]&nbsp;</p>"
            return this.parseShortCode(this.page?.copy)
        },
        mainEvents() {
            return this.events.filter((event) => {return event.type === 'big'})
        },
        otherEvents() {
            return this.events.filter((event) => {return event.type === 'small'})
        }
    },
    methods : {
        parseShortCode(shortCode) {          
            const shortcodes = [];     
            const rawShortCodes = shortCode.match(/[\w-]+=".+?"/g);
            
            for (let index = 0; index < rawShortCodes?.length; index += 3) {
                const shortcode = {};
                const element1 = rawShortCodes[index].match(/([\w-]+)="(.+?)"/);
                const element2 = rawShortCodes[index + 1].match(/([\w-]+)="(.+?)"/);
                const element3 = rawShortCodes[index + 2].match(/([\w-]+)="(.+?)"/);
                shortcode[element1[1]] = element1[2];
                shortcode[element2[1]] = element2[2];
                shortcode[element3[1]] = element3[2];
                shortcodes.push(shortcode);
            }            
            return shortcodes;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {        
        background-size: cover;        
        box-sizing: border-box;     
        position: relative;                
        @media (min-width:900px) {
            padding:130px;        
            padding-bottom: 0;    
            &:before {
                content:"";
                position: absolute;
                top:0;
                left:0;
                width:100%;
                min-height:100%;
                background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(7,7,7,0.8) 100%);
            }
        }                   

    }  
    @media (max-width:899px) {
        .text-content {
            background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(7,7,7,0.8) 100%);
        }     
        .top .title {
            background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(7,7,7,0.8) 100%);
        }
    }    
    
    .top {
        
        height:100%;
        display: grid;
        grid-template-rows: 60px 1fr;
        position: relative;
        .title {
            padding:20px 0;
            display: flex;
            align-items: center;
            gap:20px;
            &:after {
                content:"";
                height:1px;
                flex-grow: 1;                
                 background-color: rgba(#F5F5F5, 0.2);
            }
        }
    }
    .events {
        display:flex;
        @media (max-width:599px) {
             flex-direction: column;
        }        
        gap:20px;
        flex-wrap: wrap;
        align-items: flex-start;
        &.main {
            margin-bottom:20px;
            justify-content: center;
        }
    }
    .event {
        display:inline-block;        
        p {
            margin:0;
        }        
        &.big {
            padding:25px;
            background-color: rgba(#070707, 0.6);
            border-left:1px solid #F5F5F5;
            @media (min-width:600px) {
                width:calc(100% / 3 - 65px);   
            }            
            .event-date {
                border-left:5px solid #0066CC;
                padding-left:15px;
            }
        }
        &.small {
            border-left:5px solid #0066CC;
            padding:0 15px;
            @media (min-width:600px) {
                width:calc(25% - 50px);   
            }    
        }
        .event-date {
            margin-top:1rem;
            color: #F5F5F5;
            font-family: 'Gotham Book';
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
        }
        .event-title {            
            color: #F5F5F5;
            font-size: 1rem;
            text-transform: uppercase;
            font-family: 'Helvetica Neue LT Std';
            font-weight: 900;            
            letter-spacing: 0;
            line-height: 1;
        }
        &.big .event-title {
            font-size: 2.125rem;
        }
    }
</style>